import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog21 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Friedrich Hölderlin: Der Winter" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Friedrich Hölderlin: Der Winter",
                link: "/k/21",
              },
            ]}
          />
          <PageTitle>Friedrich Hölderlin: Der Winter</PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer
                src="katalog/21-christian-reiner-der-winter"
                title="Friedrich Hölderlin: Der Winter – gelesen von Christian Reiner"
              />
              <Small color="whisper">
                Mit freundlicher Genehmigung durch ECM records
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog21
